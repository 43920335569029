import { formatPrice } from '../utils/formatPrice'
import { useUserCurrency } from './useUserCurrency'

/**
 * Returns a function that formats a price in the user's currency.
 *
 * @param price - The price to format.
 * @return The formatted price, or null if the currency is not available.
 */
export function useUserPrice() {
  const currency = useUserCurrency()

  return (price: number) =>
    currency.value?.code
      ? formatPrice(price * currency.value.exchange, currency.value.code)
      : null
}
